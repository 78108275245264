@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

@import './assets/css/animate.css';
@import './assets/scss/forms.scss';

@font-face {
  font-family: "SF-Pro";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

div, p, a, span, h1, h2, h3, label, strong, small, b, button, input, mat-label, mat-form-field{
  font-family: 'Roboto', sans-serif ;
}


.SF-Pro{
  div, p, a, span, h1, h2, h3, label, strong, small, b, button, input, mat-label, mat-form-field{
    font-family: 'SF-Pro', sans-serif !important;
  }
}
.Noto-Sans{
  div, p, a, span, h1, h2, h3, label, strong, small, b, button, input, mat-label, mat-form-field{
    font-family: 'Noto Sans', sans-serif !important;
  }
}

.Arial{
  div, p, a, span, h1, h2, h3, label, strong, small, b, button, input, mat-label, mat-form-field{
    font-family: 'Arial', sans-serif !important;
  }
}

.Roboto{
  div, p, a, span, h1, h2, h3, label, strong, small, b, button, input, mat-label, mat-form-field{
    font-family: 'Roboto', sans-serif !important;
  }
}

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  --color-primary: rgb(37, 37, 37);
  --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.belender-toggle-group button[aria-pressed="true"] {
  background: linear-gradient(0deg, #385898 0%, #4a6fb9 10%, #4a6fb9,#4a6fb9 90%, #385898 100% );
  color: #ffffff;
}

mat-checkbox .mat-checkbox-label{
  white-space: pre-wrap !important;
}

.color-info svg{
  fill: #4886D3 !important;
}

.color-success svg{
  fill: #53ACA0 !important;
}

.color-warning svg{
  fill: #DB8A4D !important;
}

.color-danger svg{
  fill: #C3505E !important;
}

.color-accent svg{
  fill: #6358EC !important;
}

.bg-orange{
  background: #c2410c;
}

.text-orange{
  color: #c2410c;
}

.bg-orange-light{
  background: #c2400c19;
}

.bg-gray{
  background: #8d8d8d;
}

.text-gray{
  color: #ccc;
}

.bg-gray-light{
  background: rgba(255, 255, 255, 0.098);
}

.vex-style-default .bg-gray-light{
  background: rgba(0, 0, 0, 0.06);
}

.timeline-wrapper{
  padding-left: 11px;
}

.timeline-item{
  display: flex;
  align-items: flex-end;
  padding-top: 20px;
  border-left: 1px solid #ffffff2b;
}

.timeline-item mat-icon{
  margin: 0 10px 0px -10px;
  background: #1a202e;
}

.vex-style-default .timeline-wrapper .timeline-item mat-icon{
  background: #fff;
}

.vex-style-default .timeline-wrapper .timeline-item{
  border-left: 1px solid #0000001e;
}

.delay-0{
  animation-delay: 0ms;
}

.delay-1{
  animation-delay: 100ms;
}

.delay-2{
  animation-delay: 200ms;
}

.delay-3{
  animation-delay: 300ms;
}

.delay-4{
  animation-delay: 400ms;
}

.delay-5{
  animation-delay: 500ms;
}

.delay-6{
  animation-delay: 600ms;
}

.delay-7{
  animation-delay: 700ms;
}

.delay-8{
  animation-delay: 800ms;
}

.delay-9{
  animation-delay: 900ms;
}

.delay-10{
  animation-delay: 1000ms;
}

.delay-11{
  animation-delay: 1100ms;
}

.delay-12{
  animation-delay: 1200ms;
}

.delay-13{
  animation-delay: 1300ms;
}

.delay-14{
  animation-delay: 1400ms;
}

.delay-15{
  animation-delay: 1500ms;
}

.delay-16{
  animation-delay: 1600ms;
}

.delay-17{
  animation-delay: 1700ms;
}

.delay-18{
  animation-delay: 1800ms;
}

.delay-19{
  animation-delay: 1900ms;
}

.delay-20{
  animation-delay: 2000ms;
}

.belender-tooltip{
  background-color: #0b0d11 !important;
  text-align: center !important;
  box-shadow: 0 5px 12px #0000001f;
}

.mat-snack-bar-container.error{
  background: #f44336 !important;
  color: white !important;
}

.mat-snack-bar-container.error button{
  color: white !important;
}

.mat-snack-bar-container.info{
  background: #008bff !important;
  color: white !important;
}

.mat-snack-bar-container.info button{
  color: white !important;
}

.mat-snack-bar-container.success{
  background: #148c5a !important;
  color: white !important;
}

.mat-snack-bar-container.success button{
  color: white !important;
}

 .mat-tab-body, .mat-tab-body-content, .mat-tab-body-wrapper{
  overflow: visible !important;
}

.ql-align-center{
  text-align: center;
}

.ql-align-left{
  text-align: left;
}

.ql-align-right{
  text-align: right;
}

.widget-belender h1{
  font-size: 26px !important;
}

.widget-belender h2{
  font-size: 19.5px !important;
}

.widget-belender h3{
  font-size: 15.2px !important;
}

.widget-belender h4{
  font-size: 13px !important;
}

.widget-belender h5{
  font-size: 10.7px !important;
}

.widget-belender h6{
  font-size: 8.6px !important;
}

.widget-belender p{
  font-size: 14px !important;
}

div.ql-snow .ql-editor{
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  min-height: 226px;
  border-radius: 0 0 8px 8px;
}

span.mat-checkbox-label{
  font-size: 12px !important;
}

@media (max-width: 600px) {
  .content-widget{
      padding: 0 !important;
      min-height: 100vh;
  }

  .content-widget.content-layout{
    padding-top: 0 !important;
  }

  .content-widget .card{
      box-shadow: none !important;
  }
  
}

.widget-belender .fixed-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.belender-widget-wrapper .card mat-form-field{
  max-width: 100% !important;
}

.widget-belender .mat-horizontal-stepper-header-container{
  background: #00000008;
}

.widget-belender  .mat-horizontal-stepper-content form  {
  height: 100% !important;
  min-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.widget-belender .form-content{
  overflow: auto !important;
  max-height: calc(100vh - 158px);
}

.widget-boxed .form-content{
  max-height: 350px !important;
}

.widget-belender .actions{
  height: 60px;
}

.mat-horizontal-stepper-header-container{
  pointer-events: none !important;
}

.question-wrapper{
  min-height: 34px;
}

.transparent-content .inputs-row.not-editing{
  opacity: 0 !important;
  height: 1px;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: rgb(0, 6, 128) !important;
 }

 ::ng-deep.mat-form-field-underline {
  /*change color of underline*/
  background-color: rgb(0, 6, 128) !important;
} 

::ng-deep.mat-form-field-ripple {
 /*change color of underline when focused*/
 background-color: rgb(0, 6, 128) !important;
}

.policy-page {
  h1{
      font-size: 30px !important;
  }
  
  h2{
      font-size: 18px !important;
      margin-bottom: 15px;
      margin-top: 40px;
  }

  h3{
    font-size: 16px !important;
    margin-bottom: 15px;
    margin-top: 20px;
}
  
  ul{
      padding-left: 20px;
      list-style: disc;
  }
  
  p{
      margin: 5px 0;
  }
}

.disabled-input{
  pointer-events: none !important;
  opacity: .7;
  .mat-form-field-outline{
    background: rgba(0,0,0,0.08) !important;
    
  }
}

#terms_and_conditions_cert h1{
  font-size: 30px !important;
  font-weight: bold;
}
#terms_and_conditions_cert h2{
  font-size: 20px !important;
  font-weight: bold;
  line-height: 25px;
  margin: 15px 0;
}
#terms_and_conditions_cert p{
  margin: 10px 0;
}

//Skeleton stlyes
.animated-bg {
  background-image: linear-gradient(to right,#f6f7f8 0%,#edeef1 10%,#f6f7f8 20%,#f6f7f8 100%);
  background-size: 200% 100%;
  animation: bgPos 1s linear infinite;
}

.info-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  min-height: 40px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  margin: 10px 0;
  width: fit-content;
  a {
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .2s ease;
    @media(width <= 576px) {
      padding: 0 20px;
    }
  }
}

.custom-html {
  a {
    text-decoration: underline;
  }

  ul {
    margin-left: 15px;
    li {
      list-style: disc;
    }
  }
}